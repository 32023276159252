.btnDanger{
    background-color: '#ff4d4f';color:'#ffff'
}
.formDevide {
    margin-bottom: 8px; /* Margin horizontal 10px */
}
.isi {
    font-size: 15px;
    margin-top: -10px;
    margin-bottom: 8px; /* Margin horizontal 10px */
}
.custom-row-class {
    background-color: #4CAF50; /* Warna latar belakang sesuai keinginan Anda */
    /* Tambahan gaya CSS lainnya jika diperlukan */
  }