.btnDanger{
    background-color: '#ff4d4f';color:'#ffff'
}
.formDevide {
    margin-bottom: 8px; /* Margin horizontal 10px */
}
.labelKecil{
    font-size: 11px;
}

.zero-amount-row {
    background-color: #636363;
    color: rgb(255, 255, 255);
  }